<template>
  <div>
    <b-row>
      <b-col sm="12" md="6">
        <InputText
          textFloat="Maximum days booking (Day)"
          placeholder="0"
          type="number"
          name="number"
          v-model="form.maximumDay"
      /></b-col>
      <b-col sm="12" md="6">
        <InputText
          v-model="form.minimumDay"
          textFloat="Minimum days required before booking (Day)"
          placeholder="0"
          type="number"
          name="number"
      /></b-col>
      <b-col sm="12" md="6">
        <InputText
          v-model="form.quantity"
          textFloat="จำนวนต่อรอบ"
          placeholder="0"
          type="number"
          name="number"
          :v="v.form.quantity"
          :isValidate="v.form.quantity.$error"
      /></b-col>
    </b-row>

    <b-row
      ><b-col>
        <div class="tw-flex tw-w-full tw-justify-between tw-mb-2">
          <h1 class="title">
            RoundTime <span class="highlight tw-text-red-500">*</span>
          </h1>
          <b-button @click="handleSideBar" class="">RoundTime</b-button>
        </div>

        <TableRoundTimeForm :items="items" :v="v"
      /></b-col>
    </b-row>

    <RoundTimeForm
      :visible="visible"
      @onSaveForm="onSaveForm"
      @clearForm="clearForm"
      :dutation="form.dutation"
      :bufferTimeAfter="form.bufferTimeAfter"
      :items="items"
      @openModalAlertText="openModalAlertText"
    />
    <div
      class="tw-sticky tw-bottom-0 tw-z-40 tw-w-full tw-h-16 tw-px-3 tw-mt-10"
    >
      <div class="tw-flex tw-justify-between">
        <b-button
          class="btn-filter cancel"
          variant="secondary"
          @click="$emit('onClickBack')"
          :disabled="loading"
          >Back</b-button
        >
        <b-button
          class="btn-filter save"
          @click="$emit('onClickNext')"
          :disabled="loading"
          >{{ current_step < max_step - 1 ? "Next" : "Save" }}
          <b-spinner
            class="m-0 ml-1"
            label="Spinning"
            small
            v-if="loading"
          ></b-spinner>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import RoundTimeForm from "./roundTimeForm.vue";
import TableRoundTimeForm from "./tableRoundTimeForm.vue";
export default {
  name: "calendarForm",
  components: { RoundTimeForm, TableRoundTimeForm },
  props: {
    form: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
    current_step: {
      type: Number,
      required: true,
    },
    max_step: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return { visible: false };
  },
  methods: {
    handleSideBar() {
      this.visible = true;
    },
    onSaveForm(val) {
      const addItem = {
        index: this.items.length,
        ...val,
        timeFrom: this.$moment(val.timeFrom).format("HH:mm:ss"),
      };

      this.items.push(addItem);
    },
    clearForm() {
      this.visible = false;
    },
    openModalAlertText(val) {
      this.$emit("openModalAlertText", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

// ! override
.btn-filter {
  width: 100%;
  max-width: 150px;

  &.cancel {
    border: none;
    background: #606060;
    color: white !important;
  }

  &.save {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    border: none;
    color: white !important;
  }
  &.save:hover {
    background: var(--secondary-color);

    cursor: pointer;
  }
  &.cancel:hover {
    background: #606060;
    color: white !important;

    cursor: pointer;
  }
}
</style>
