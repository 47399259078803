<template>
  <div>
    <div class="d-stepper tw-mt-8 tw-mb-2">
      <div class="d-stepper-header d-flex justify-content-around">
        <div
          class="step-number-content text-center"
          :class="{ active: current_step == i }"
          v-for="(stepItem, i) in max_step"
          :key="i"
        >
          <div
            class="step-number align-items-center justify-content-center mx-auto"
            :class="stepNumberClasses(i)"
          >
            <span>{{ i + 1 }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-h-5/6" v-show="current_step === 0">
      <BranchForm
        :id="id"
        :visible="visible"
        :formStep="formStep"
        :dutationFlag="true"
        :branchCodeFlag="true"
        @onClickNext="onClickNext"
        @onClickBack="onClickBack"
      />
    </div>
    <div class="tw-h-5/6" v-show="current_step === 1">
      <CalendarForm
        :form="form"
        :loading="isLoading"
        :items="form.productPriceManage"
        :max_step="max_step"
        :current_step="current_step"
        @onClickBack="onClickBack"
        @onClickNext="onClickNext"
        :v="$v"
        @openModalAlertText="openModalAlertText"
      />
    </div>

    <ModalConfirmDelete
      :nameItem="''"
      @confirm="confirmModal"
      :text="'หากท่านกดยืนยันข้อมูลที่ท่านกรอกจะหายไปทั้งหมด'"
    />
    <ModalAlertText :textModal="textModal" :arrModal="[]" />
  </div>
</template>

<script>
import { requiredIf, minValue } from "vuelidate/lib/validators";
import Stepper from "@/components/Stepper.vue";
import BranchForm from "../component/branchForm.vue";
import ModalConfirmDelete from "@/components/modal/ModalConfirmDelete.vue";
import CalendarForm from "../component/calendarForm.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
export default {
  name: "CreateBranch",
  components: {
    BranchForm,
    Stepper,
    ModalConfirmDelete,
    CalendarForm,
    ModalAlertText,
  },
  data() {
    return {
      id: 0,
      visible: false,
      formStep: true,

      current_step: 0,
      max_step: 2,

      isLoading: false,
      textModal: "",
      form: {
        isLockProductEvent: true,
        quantity: 1,
        productPriceManage: [],
      },
    };
  },
  validations() {
    return {
      form: {
        quantity: {
          required: requiredIf(() => this.current_step === 1),
          minValue: minValue(1),
        },
        dutation: {
          required: requiredIf(() => this.current_step === 1),
          minValue: minValue(1),
        },
        productPriceManage: {
          required: requiredIf(() => this.current_step === 1),
        },
      },
    };
  },
  mounted() {
    this.visible = true;
  },

  methods: {
    stepNumberClasses(i) {
      return {
        "current-step  text-white": this.current_step === i,
      };
    },
    async handleSave() {
      this.isLoading = true;
      const payload = {
        ...this.form,
        endReserveDate:
          this.form.endReserveDate === "" ? null : this.form.endReserveDate,
        startReserveDate:
          this.form.startReserveDate === "" ? null : this.form.startReserveDate,
      };

      var respone = await this.$services.venue.saveClient(payload);
      if (respone.result >= 1) {
        this.$router.push({
          path: `/branch/detail/${respone.detail.venueId}`,
        });
      } else {
        this.openModalAlertText(respone.message);
      }
      this.isLoading = false;
    },
    onClickNext: function (value) {
      if (this.current_step < this.max_step - 1) {
        this.form = {
          ...this.form,
          ...value,
          productPriceManage: [...this.form.productPriceManage],
        };
        this.current_step++;
      } else {
        this.$v.form.$touch();

        if (this.$v.form.$error) {
          return;
        }
        this.handleSave();
        this.$v.form.$reset();
      }
    },
    onClickBack: function () {
      switch (this.current_step) {
        case 0:
          this.$router.push({
            path: `/branch`,
          });
          break;
        case 1:
          this.openModalConfirm();
          break;
        default:
          break;
      }
    },
    backStep() {
      if (this.current_step > 0) this.current_step--;
    },
    confirmModal() {
      this.clearForm();
      this.backStep();
      this.closeModalConfirm();
    },
    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
    clearForm() {
      this.form = {
        productPriceManage: [],
      };
    },
    openModalConfirm() {
      this.$bvModal.show("modal-confirm-delete");
    },

    closeModalConfirm() {
      this.$bvModal.hide("modal-confirm-delete");
    },
  },
};
</script>

<style lang="scss" scoped>
.current-step {
  background: var(--secondary-color);
}
.old-step {
  background: var(--secondary-color);
}
</style>
